import { gql } from "@apollo/client";

export const CHANGE_PASSWORD_USER_MUTATION = gql`
  mutation changePasswordUser(
    $oldPassword: String!
    $password: String!
    $passwordConfirmation: String!
  ) {
    changePasswordForUser(
      oldPassword: $oldPassword
      password: $password
      passwordConfirmation: $passwordConfirmation
    ) {
      success
    }
  }
`;
