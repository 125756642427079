import { FileChecksum } from "@rails/activestorage/src/file_checksum";
import { BlobUpload } from "@rails/activestorage/src/blob_upload";
import {
  apolloClient,
  CREATE_DIRECT_UPLOAD_MUTATION,
  DESTROY_FILE_MUTATION,
} from "../apollo";
import Compressor from "compressorjs";
import { captureException } from "./sentry.helper";

export const convertToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    if (!file) {
      reject("File does not exist");
    }
    const reader = new FileReader();
    reader.onloadend = function () {
      resolve(reader.result);
    };
    reader.readAsDataURL(file);
  });
};

export const compressImage = (file) => {
  return new Promise((resolve, reject) => {
    if (file.size < 1 * 1000 * 1000) {
      resolve(file);
    }
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        resolve(result);
      },
      error(err) {
        reject(err.message);
      },
    });
  });
};

export const calculateChecksum = (file) => {
  return new Promise((resolve, reject) => {
    FileChecksum.create(file, (error, checksum) => {
      if (error) {
        reject(error);
        return;
      }

      resolve(checksum);
    });
  });
};

export const directUpload = (url, headers, file) => {
  const upload = new BlobUpload({ file, directUploadData: { url, headers } });
  return new Promise((resolve, reject) => {
    upload.create((error) => {
      if (error) {
        reject(error);
      } else {
        resolve("Completed");
      }
    });
  });
};

export const uploadFile = async (file) => {
  const checksum = await calculateChecksum(file);
  const { data } = await apolloClient.mutate({
    mutation: CREATE_DIRECT_UPLOAD_MUTATION,
    variables: {
      filename: file.name,
      byteSize: file.size,
      checksum: checksum,
      contentType: file.type,
    },
  });

  const { headers, url, signedBlobId } = data.createDirectUpload;

  await directUpload(url, JSON.parse(headers), file);

  return signedBlobId;
};

export const removeFile = async (id) => {
  try {
    const { data } = await apolloClient.mutate({
      mutation: DESTROY_FILE_MUTATION,
      variables: { id },
    });

    return data.destroyFile?.success;
  } catch (error) {
    captureException("File Helper", error);
    return false;
  }
};
