export const RECAPTCHA_CLIENT_KEY = process.env.REACT_APP_RECAPTCHA_KEY;
export const GOOGLE_RECAPTCHA_URL = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_CLIENT_KEY}`;

export const handleLoadReCaptcha = () => {
  const loadScriptByURL = (id, url, callback) => {
    const isScriptExist = document.getElementById(id);

    if (!isScriptExist) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      script.src = url;
      script.id = id;
      script.onload = function () {
        if (callback) callback();
      };
      document.body.appendChild(script);
    }

    if (isScriptExist && callback) callback();
  };

  // load the script by passing the URL
  loadScriptByURL("recaptcha-key", GOOGLE_RECAPTCHA_URL, () => {
    console.log("ReCaptcha Script loaded!");
  });
};
