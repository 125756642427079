import { useRequest } from "ahooks";
import React from "react";
import { Helmet } from "react-helmet";
import { delay } from "~/common/helpers";

export const Meta = () => {
  const { data, loading } = useRequest(async () => {
    await delay(300);
    const branchData = await window.host.getBranchData();

    const data = branchData.data_parsed;
    return data;
  });

  if (loading || !data) {
    return null;
  }

  const tags = [
    data.$og_title && (
      <meta key="1" property="og:title" content={data.$og_title} />
    ),
    data.$twitter_title && (
      <meta key="2" name="twitter:title" content={data.$twitter_title} />
    ),

    data.$og_description && (
      <meta key="3" property="og:description" content={data.$og_description} />
    ),

    data.$twitter_description && (
      <meta
        key="4"
        name="twitter:description"
        content={data.$twitter_description}
      />
    ),

    data.$canonical_url && (
      <link key="5" rel="canonical" href={data.$canonical_url} />
    ),

    data.$og_image_url && (
      <meta key="6" property="og:image" content={data.$og_image_url} />
    ),
    data.$twitter_image_url && (
      <meta
        key="7"
        property="twitter:image:src"
        content={data.$twitter_image_url}
      />
    ),
    <title key="8">{data.$og_title || "HealthGig"}</title>,
    <meta key="9" name="title" content={data.$og_title || "HealthGig"} />,
    <meta
      key="10"
      name="description"
      content={data.$og_description || "HealthGig Description"}
    />,
  ].filter(Boolean);

  if (tags.length === 0) {
    return null;
  }

  return <Helmet>{tags}</Helmet>;
};
