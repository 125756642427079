import { isEmpty } from "lodash";
import { EVENTS } from "~/common/constants";
import {
  store,
  observable,
  action,
  computed,
  event,
} from "~/common/mobx.decorator";
import Bowser from "bowser";

@store()
class NotifyStore {
  @observable messages = [];
  @observable doneMessages = {};

  @observable dialogs = [];
  @observable doneDialogs = {};

  @computed
  get waitMessages() {
    return this.messages.filter((message) => !this.doneMessages[message.key]);
  }

  @computed
  get waitDialogs() {
    return this.dialogs.filter((dialog) => !this.doneDialogs[dialog.key]);
  }

  @computed
  get signal() {
    return window.OneSignal || [];
  }

  @action
  open = async (message, options) => {
    const data = {
      key: `${Date.now()}_${Math.random()}`,
      message,
      options,
    };

    if (options.showDialog) {
      this.dialogs.push(data);

      return data.key;
    }

    // if (this.signal.initialized && routerStore.os?.name !== 'iOS' && Notification?.permission === 'granted') {
    // 	return this.signal.push(() => {
    // 		this.signal.sendSelfNotification(options?.title || appStore.name, message, options?.url, options?.icon)
    // 	})
    // }

    if (options?.onlyWebPush) {
      return;
    }

    this.messages.push(data);
    return data.key;
  };

  @action
  success = async (message, options) => {
    return this.open(message, { ...options, variant: "success" });
  };

  @action
  error = async (message, options) => {
    return this.open(message, { ...options, variant: "error" });
  };

  @action
  info = async (message, options) => {
    return this.open(message, { ...options, variant: "info" });
  };

  @action
  warning = async (message, options) => {
    return this.open(message, { ...options, variant: "warning" });
  };

  @action
  closeMessage = async (key) => {
    this.doneMessages[key] = true;
  };

  @action
  closeDialog = async (key) => {
    this.doneDialogs[key] = true;
  };

  @event(EVENTS.notifyStore.fireError)
  handleError({ payload }) {
    this.error(payload?.message);
  }

  @action
  initOneSignal = async (config, worker, notificationChannel) => {
    if (!isEmpty(config?.oneSignalAppId)) {
      const browser = Bowser.getParser(window.navigator.userAgent);

      this.signal.push(() => {
        this.signal.init({
          appId: config?.oneSignalAppId,
          autoRegister: true,
          autoResubscribe: true,
          allowLocalhostAsSecureOrigin: true,
          notifyButton: { enable: true },
        });
      });

      setTimeout(() => {
        this.signal.push(() => {
          this.signal
            .sendTags({
              companyId: config?.companyId,
              userType: "Worker",
              browser: browser.getBrowser(),
              workerId: worker?.id,
              workerEmail: worker?.email,
            })
            .then((tagsSent) => {
              console.log("**************");
              console.log("tagsSent: ", tagsSent);
            });
        });

        this.signal.push(() => {
          this.signal.setExternalUserId(notificationChannel);
        });

        this.signal.getExternalUserId().then((externalUserId) => {
          console.log("**************");
          console.log("externalUserId: ", externalUserId);
        });
      }, 3000);
    }
  };
}

export const notifyStore = new NotifyStore();
