import React from "react";
import { useSnackbar } from "notistack";
import { observer, autorun } from "~/common/mobx.decorator";
import { notifyStore } from "~/stores";
import { Button } from "~/components/button";
import { useTranslation } from "@opus/web.core.hooks.use-translation";
import { NotifyDialog } from "./notify-dialog.component";
import { defaultTo } from "lodash";
import { Typography } from "../typography";

export const GlobalNotify = observer(() => {
  const { t } = useTranslation();
  const { waitDialogs, doneDialogs, waitMessages, doneMessages } = notifyStore;

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  autorun(() => {
    waitMessages.forEach((note) => {
      const { key, message, options } = note;
      if (doneMessages[key]) {
        return;
      }

      const actions = options.persist
        ? [...options.actions, { label: "DISMISS" }]
        : options.actions;

      enqueueSnackbar(t(message), {
        key,
        ...options,
        action: () =>
          actions?.map(({ onClick, label, keepSnackbar, ...rest }) => {
            const handleClick = () => {
              if (typeof onClick === "function") {
                onClick();
              }

              if (!keepSnackbar) {
                closeSnackbar(key);
              }
            };

            return (
              <Button key={key} {...rest} onClick={handleClick}>
                {t(label)}
              </Button>
            );
          }),
      });

      notifyStore.closeMessage(key);
    });
  });

  return waitDialogs.map(({ key, message }) => (
    <NotifyDialog
      key={key}
      open={defaultTo(doneDialogs[key], true)}
      onOk={() => notifyStore.closeDialog(key)}
    >
      <Typography whiteSpace="pre-line" align="center">
        {t(message)}
      </Typography>
    </NotifyDialog>
  ));
});
