import { css } from "styled-components";
import { fontWeight, palette } from "@material-ui/system";
import { textTransform, whiteSpace, opacity } from "~/themes/mixins";

export const typographyStyle = css`
  display: flex;
  align-items: center;

  .MuiSvgIcon-root {
    margin-right: 0.625rem;
  }

  ${whiteSpace};
  ${textTransform};
  ${fontWeight};
  ${palette};
  ${opacity};
`;
