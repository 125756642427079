import { store, observable, persist, action } from "~/common/mobx.decorator";

@store({ persist: true })
class UIStore {
  @persist @observable theme = "care";

  @action
  async changeTheme(theme) {
    this.theme = theme;
  }
}

export const uiStore = new UIStore();
