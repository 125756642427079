import React, { useCallback } from "react";
import { Button as MuiButton, CircularProgress } from "@material-ui/core";
import { buttonStyle } from "./button.style";
import { eventClient } from "@opus/web.core.lib.event-tracking";

export const Button = ({
  loading,
  children,
  disabled,
  startIcon,
  ...props
}) => {
  const handleClick = useCallback(() => {
    if (props.onTrackingEvent) {
      props.onTrackingEvent();
    } else if (props.eventId) {
      eventClient
        .logClickEvent(props.eventId, props.eventParams)
        .catch((error) =>
          console.log(`Error tracking data ${props.eventId}`, error.message)
        );
    }
  }, [props]);

  return (
    <MuiButton
      disabled={loading || disabled}
      startIcon={loading ? <CircularProgress size={20} /> : startIcon}
      css={buttonStyle}
      onClick={handleClick}
      {...props}
    >
      {children}
    </MuiButton>
  );
};

Button.defaultProps = {
  fullWidth: true,
  color: "primary",
  variant: "contained",
  size: "large",
};
